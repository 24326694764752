import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const Aligner = makeShortcode("Aligner");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "there-will-be-buds-solved",
      "style": {
        "position": "relative"
      }
    }}>{`There Will Be Buds (Solved)`}<a parentName="h1" {...{
        "href": "#there-will-be-buds-solved",
        "aria-label": "there will be buds solved permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <blockquote>
      <p parentName="blockquote">{`"For how many aeons have secret blossoms been budding and falling?"`}</p>
    </blockquote>
    <p><em parentName="p">{`The lotus flower`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Is unstained by mud;`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`This single dewdrop,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Just as it is,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Manifests the real body of truth.`}</em></p>
    <p>{`Move with us through `}<a parentName="p" {...{
        "href": "/learn/module-1"
      }}>{`module one`}</a>{` in order to find meaning which is valuable within the context of your own life. Honest appreciation of how such patterns play out in your own experience, combined with trust that we all share the same ground, may lead you into a deeper, lived awareness of how value and meaning arises in the space which only seems to separate us.`}</p>
    <p>{`For those who make it through, there is a unique NFT courtesy of Adrian le Bas and the `}<Link to="https://ethblock.art/view/117" mdxType="Link">{`ethblock.art`}</Link>{` team. It is a rare, budding Maurer Rose, uniquely generated from Block 1987064.`}</p>
    <p>{`The riddle begins `}<Link to="https://libraryofbabel.info/bookmark.cgi?truthbud" mdxType="Link">{`here`}</Link>{`. `}<em parentName="p">{`Hamba kahle`}</em>{`.`}</p>
    <Aligner center mdxType="Aligner">
      <Image style={{
        "width": "60%"
      }} src="/images/koans/bud_of_bud.png" alt="The Bud of the Bud" mdxType="Image" />
    </Aligner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      